<template>
  <section class="conOfEventItem h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'Annual', part2: 'Meeting' }" />
        </v-col>
      </v-row>
    </v-container>

    <section class="mt-3" v-if="itemResponse && itemResponse.length > 0">
      <div>
        <v-container>
          <section class="conOfHeader">
            <v-row>
              <v-col md="8" cols="12">
                <div class="headerSec">{{ itemResponse[0].title }}</div>
              </v-col>
              <!-- <v-col md="4" cols="12">
                <div class="shareBtnCon text-right">
                  <v-btn class="eventShareBtn" text :ripple="false">
                    share
                    <v-icon>mdi-share-variant</v-icon>
                  </v-btn>
                </div>
              </v-col> -->
            </v-row>
          </section>
        </v-container>
      </div>

      <v-container>
        <v-row>
          <v-col md="6" cols="12">
            <div class="conofcardSec">
              <div class="descSec" v-html="itemResponse[0].description"></div>
            </div>
          </v-col>
          <v-col md="6" cols="12">
            <div class="conSecImage">
              <div class="conOfCardImg">
                <v-img
                  :src="itemResponse[0].image"
                  alt="item of the Image"
                ></v-img>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import HeaderPage from "@/modules/shared/components/header-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    BreadcrumbList: [
      {
        text: "Research",
        disabled: true,
      },
      {
        text: "Annual Meetings",
        disabled: false,
      },
    ],
    itemResponse: null,
    isLoading: false,
  }),
  components: {
    EmptyState,
    Breadcrumb,
    HeaderPage
  },
  methods: {
    getListData() {
      this.itemResponse = [];
      this.isLoading = true;

      apiServices.get("annual-meeting/" + this.$route.params.id).then((res) => {
        if (res) {
          this.itemResponse = res.data;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_annual-meetings-item.scss";
</style>
